import React, { Component, useState } from "react";
import { css, injectGlobal } from "emotion";
import Loading from "react-loading";
import "normalize.css";
import { apiPost } from "./api";

injectGlobal`
  html,
  body {
    width: 100%;
    min-height: 100%;
    background-color: rgb(234, 238, 241);    
    margin: 0;
    padding: 0;
  }

  html {
    font-size: 62.5%;
  }

  body {
    font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 1.6rem;
  }

  body,
  #root {
    width: 100%;
    height: 100vh;
  }

  * {
    box-sizing: border-box;
  }

  label {
    font-weight: 600;
    margin: 10px 0;
    display: inline-block;
  }
`;

const Happy = ({ google_link, facebook_link, yelp_link }) => (
  <div
    className={css`
      font-size: 1.6rem;
    `}
  >
    <div
      className={css`
        font-weight: 700;
        text-align: center;
        font-size: 3rem;
      `}
    >
      We're happy you're happy!
    </div>
    <div
      className={css`
        font-weight: 300;
        text-align: center;
        font-size: 2rem;
      `}
    >
      <p>
        We'd really appreciate a review on Google, Yelp, or Facebook. It only
        takes 30 seconds and would be a big help, so thank you in advance!
      </p>
      {google_link && (
        <p>
          <a href={google_link}>Here's the Google link</a>
        </p>
      )}
      {facebook_link && (
        <p>
          If you'd like to leave a review on facebook,{" "}
          <a href={facebook_link}>you can do that by clicking here.</a>
        </p>
      )}
      {yelp_link && (
        <p>
          Or, if you're an avid Yelp user,{" "}
          <a href={yelp_link}>you can review us on Yelp here.</a>
        </p>
      )}
      <p
        className={css`
          font-weight: 700;
        `}
      >
        Thanks for your support!
      </p>
    </div>
  </div>
);

const Sad = ({ google_link, facebook_link, yelp_link }) => {
  const [isDone, setDone] = useState(false);

  return (
    <div>
      <div
        className={css`
          font-weight: 700;
          text-align: center;
          font-size: 3rem;
        `}
      >
        Thanks for your feedback!
      </div>
      <div
        className={css`
          font-weight: 300;
          text-align: center;
        `}
      >
        <p
          className={css`
            font-size: 2rem;
          `}
        >
          We'd love to hear more about your rating so we can continue to
          improve. Please use the box below to provide additional feedback.
        </p>
        {isDone ? (
          <div>
            <center>
              <p>Thanks!</p>
            </center>
          </div>
        ) : (
          <div>
            <textarea
              placeholder="Add comments here"
              className={css`
                width: 400px;
                padding: 10px;
                max-width: 100%;
                margin-bottom: 20px;
              `}
            />
            <center>
              <button onClick={() => setDone(true)}>Submit</button>
            </center>
          </div>
        )}
        <br />
        <p
          className={css`
            font-weight: 700;
          `}
        >
          Thanks for helping us improve!
        </p>
        <p>
          You can also leave us a review on <a href={google_link}>Google</a>,{" "}
          <a href={facebook_link}>Facebook</a>, or <a href={yelp_link}>Yelp</a>.
        </p>
      </div>
    </div>
  );
};

function getUrlParameter(name) {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  const regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  const results = regex.exec(window.location.search);
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}

class App extends Component {
  state = { loading: true };

  componentDidMount() {
    const score = getUrlParameter("score");
    const token = getUrlParameter("id");

    apiPost("nps", { score, token }).then(r => {
      this.setState({
        score,
        token,
        loading: false,
        notFound: r.resp === "error",
        completed: r.data && r.data.resp === "completed",
        data: r.data
      });
    });
  }

  render() {
    const { loading, data, score, notFound } = this.state;

    return (
      <div
        className={css`
          display: flex;
          width: 100%;
          height: 100%;
          align-items: center;
          justify-content: center;
        `}
      >
        {loading ? (
          <Loading />
        ) : (
          <div
            className={css`
              background-color: #fff;
              border-radius: 0px;
              box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
              width: 700px;
              max-width: 100%;
              padding: 30px;
            `}
          >
            <div
              className={css`
                display: flex;
                justify-content: center;
              `}
            >
              {data && data.org_logo && (
                <img
                  style={{
                    marginBottom: "30px",
                    maxWidth: "100%",
                    maxHeight: "300px"
                  }}
                  src={data.org_logo}
                  alt="Logo"
                />
              )}
            </div>
            {notFound && (
              <div
                className={css`
                  text-align: center;
                  font-weight: 700;
                  font-size: 2rem;
                `}
              >
                We're sorry! This appointment was not found.
              </div>
            )}
            {parseInt(score, 10) >= 8 ? <Happy {...data} /> : <Sad {...data} />}
          </div>
        )}
      </div>
    );
  }
}

export default App;
